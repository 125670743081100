import { clearSection, clearSubSection, setMssConfiguration, setUserConfiguration, }                     from 'dmpconnectjsapp-base/actions';
import {
  formatPerformMssAuthentication
}                                                                                                        from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import commands, {
  mssOperators, mssSubTypes
}                                                                                                        from 'dmpconnectjsapp-base/actions/config/commands';
import {
  apiSections, mssLoginTypes, mssReceiptNotificationTypes,
}                                                                                                        from 'dmpconnectjsapp-base/constants';
import {
  getApiType, getMssSenderWording
}                                                                                                        from 'dmpconnectjsapp-base/helpers/accessors';
import {
  getError, hasError, isLoading, isReady,
}                                                                                                        from 'dmpconnectjsapp-base/helpers/common';
import {
  authenticationTypes
}                                                                                                        from 'dmpconnectjsapp-base/rules/accessRights';
import JSum                                                                                              from 'jsum';
import PropTypes                                                                                         from 'prop-types';
import React                                                                                             from 'react';
import { Form, Row }                                                                                     from 'react-bootstrap';
import Button                                                                                            from 'react-bootstrap/Button';
import Modal                                                                                             from 'react-bootstrap/Modal';
import { connect }                                                                                       from 'react-redux';
import { toast }                                                                                         from 'react-toastify';
import { addMssAccount, getAction, setMssAccountActive, setPersistedAppConfiguration, updateMssAccount } from '../../dmpconnect/actions';
import { API_TYPES }                                                                                     from '../../dmpconnect/constants';
import userManager
                                                                                                         from '../../dmpconnect/constants/userManager';
import { createErrorDetails }                                                                            from '../../dmpconnect/errors';
import {
  errorActions
}                                                                                                        from '../../dmpconnect/errors/errorActions';
import {
  generateMessageId, getDefaultMssAccountLabel, getSendMssEmailAction, MssConfigSchema
}                                                                                                        from '../../dmpconnect/helpers/mss';
import {
  getXmlElement
}                                                                                                        from '../../dmpconnect/helpers/xml';
import {
  b64DecodeUnicode, generateUniqueId, isEmailValid, objectWithoutTheseKeys,
}                                                                                                        from '../../dmpconnect/utils/dataUtils';
import env                                                                                               from '../../envVariables';
import checkAccessRights
                                                                                                         from '../AccessRights/CheckAccessRights';
import ButtonWithLoader
                                                                                                         from '../Common/Form/ButtonWithLoader';
import Loading                                                                                           from '../Common/Loading/Loading';
import GetLogs
                                                                                                         from '../Common/Logs/GetLogsButton';
import Alert                                                                                             from '../Common/Message/Alert';
import ErrorDetails
                                                                                                         from '../Common/Message/ErrorDetails';
import ErrorMessage
                                                                                                         from '../Common/Message/ErrorMessage';
import TitleTooltip                                                                                      from '../Common/TitleTooltip';

// const mailizDomains = [
//   `pro.${Number(env.REACT_APP_DEBUG) === 1 ? 'formation.' : ''}mssante.fr`,
//   `medecin.${Number(env.REACT_APP_DEBUG) === 1 ? 'formation.' : ''}mssante.fr`,
//   `pharmacien.${Number(env.REACT_APP_DEBUG) === 1 ? 'formation.' : ''}mssante.fr`,
//   `chirurgien-dentiste.${Number(env.REACT_APP_DEBUG) === 1 ? 'formation.' : ''}mssante.fr`,
//   `sage-femme.mssante.${Number(env.REACT_APP_DEBUG) === 1 ? 'formation.' : ''}fr`,
//   `pedicure-podologue.${Number(env.REACT_APP_DEBUG) === 1 ? 'formation.' : ''}mssante.fr`,
//   `masseur-kinesitherapeute.${Number(env.REACT_APP_DEBUG) === 1 ? 'formation.' : ''}mssante.fr`,
//   `infirmier.mssante.${Number(env.REACT_APP_DEBUG) === 1 ? 'formation.' : ''}fr`,
//   `social.mssante.${Number(env.REACT_APP_DEBUG) === 1 ? 'formation.' : ''}fr`,
//   `orthophoniste.${Number(env.REACT_APP_DEBUG) === 1 ? 'formation.' : ''}mssante.fr`,
// ];

// const isMailiz = (email) => {
//   if (MssConfigSchema.isValidSync({ mssEmail: email })) {
//     const parts = email.split('@');
//     return mailizDomains.includes(parts[1]);
//   }
//   return true;
// };

function MssConfig({
  dispatch,
  apiType,
  getEmailSection,
  getEmailIsLoading,
  accessRights,
  columnLayout,
  mssActive,
  config,
  getEmailError,
  title,
  esUser,
  performMssAuthSection,
  
  mssPscAccessToken,
  
  onSubmit,
  onEnd,
  
  checkConfigFolders,
  checkConfigSmtp,
  
  setImapCertSection,
  setSmtpCertSection,
  setWsCertSection,
  
  mssDefaultConfig,
  
  mssAccount,
  mssAccounts,
}) {
  const [advanced, setAdvanced]                                          = React.useState(false);
  const [account, setAccount]                                            = React.useState(mssAccount);
  const [rpps, setRpps]                                                  = React.useState(esUser ? esUser.hpInternalId : accessRights.psId);
  const [submitting, setSubmitting]                                      = React.useState(false);
  const [pscAccessToken, setPscAccessToken]                              = React.useState(mssPscAccessToken);
  const [emails, setEmails]                                              = React.useState([]);
  const [checkConfigImapErrorDetails, toggleCheckConfigImapErrorDetails] = React.useState(false);
  const [checkConfigSmtpErrorDetails, toggleCheckConfigSmtpErrorDetails] = React.useState(false);
  const [changed, setChanged]                                            = React.useState(false);
  const [waitForCerts, setWaitForCerts]                                  = React.useState(false);
  const [waitForImapCert, setWaitForImapCert]                            = React.useState(false);
  const [waitForSmtpCert, setWaitForSmtpCert]                            = React.useState(false);
  const [waitForWsCert, setWaitForWsCert]                                = React.useState(false);
  
  const [receiptNotificationType, setReceiptNotificationType] = React.useState(mssDefaultConfig.mssReceiptNotificationType);
  
  React.useEffect(() => {
    setPscAccessToken(mssPscAccessToken);
  }, [mssPscAccessToken]);
  
  React.useEffect(() => {
    if (!mssAccount.id) {
      setAccount({
        ...mssAccount,
        mssOperator: (mssDefaultConfig.mssOperatorsConfig || {}).some(operatorConfig => operatorConfig.id === mssDefaultConfig.mssOperator)
                     ? mssDefaultConfig.mssOperator
                     : undefined
      });
    } else {
      setAccount(mssAccount);
    }
  }, [mssAccount.id]);
  
  React.useEffect(() => {
    const operatorConfig = (mssDefaultConfig.mssOperatorsConfig || {}).find(c => c.id === account.mssOperator);
    if (operatorConfig) {
      
      const { dev = {}, prod = {} } = operatorConfig;
      
      let configImapServer;
      let configImapPort;
      let configSmtpServer;
      let configSmtpPort;
      
      if (Number(env.REACT_APP_PRODUCTON_MODE) === 1) {
        configImapServer = prod.imapServer;
        configImapPort   = prod.imapPort;
        configSmtpServer = prod.smtpServer;
        configSmtpPort   = prod.smtpPort;
      } else {
        configImapServer = dev.imapServer;
        configImapPort   = dev.imapPort;
        configSmtpServer = dev.smtpServer;
        configSmtpPort   = dev.smtpPort;
      }
      
      setAccount(prevAccount => ({
        ...prevAccount,
        mssApiType   : operatorConfig.api,
        mssLoginType : operatorConfig.loginType,
        mssImapServer: configImapServer || '',
        mssImapPort  : configImapPort || '',
        mssSmtpServer: configSmtpServer || '',
        mssSmtpPort  : configSmtpPort || '',
      }));
      setChanged(true);
    }
  }, [mssDefaultConfig.mssOperatorsConfig, account.mssOperator, mssAccount.mssOperator, account.id]);
  
  const showLoginPassword = React.useMemo(() => {
    const operatorConfig                                = (mssDefaultConfig.mssOperatorsConfig || {}).find(c => c.id === account.mssOperator);
    const { showImapLogin = true, enableLoginPassword } = operatorConfig || {};
    if (enableLoginPassword !== undefined) {
      return enableLoginPassword;
    }
    return showImapLogin;
  }, [account.mssOperator, mssDefaultConfig.mssOperatorsConfig]);
  
  React.useEffect(() => {
    if (account.mssEmail) {
      if (!showLoginPassword) {
        setAccount(prevAccount => ({
          ...prevAccount,
          mssImapLogin: account.mssEmail,
          mssSmtpLogin: account.mssEmail,
        }));
      }
    }
  }, [account.mssEmail, showLoginPassword]);
  
  React.useEffect(() => {
    const accountValues    = objectWithoutTheseKeys(account, ['validated']);
    const mssAccountValues = objectWithoutTheseKeys(mssAccount, ['validated']);
    if (
      (JSum.digest(accountValues, 'SHA256', 'hex') !== JSum.digest(mssAccountValues, 'SHA256', 'hex'))
      || (!!rpps && rpps !== (esUser ? esUser.hpInternalId : accessRights.psId))
      || (!!pscAccessToken && pscAccessToken !== mssPscAccessToken)
    ) {
      setChanged(true);
    }
  }, [
    account, mssAccount,
    rpps, esUser, accessRights.psId,
    pscAccessToken, mssPscAccessToken,
  ]);
  
  React.useEffect(() => {
    if (isReady(getEmailSection)) {
      const { s_answerBodyInBase64 } = getEmailSection;
      const body                     = b64DecodeUnicode(s_answerBodyInBase64);
      const parser                   = new DOMParser();
      const xmlDoc                   = parser.parseFromString(body, 'text/xml');
      
      const emailsNodeList = getXmlElement(xmlDoc, 'emails', 0).childNodes;
      
      if (emailsNodeList) {
        const emailsList = [...emailsNodeList.values()].map(e => e.nodeValue);
        if (emailsList.length > 1) {
          setEmails(emailsList);
        } else if (emailsList.length === 1) {
          setAccount(prevAccount => ({
            ...prevAccount,
            mssEmail: emailsList[0],
          }));
        }
      }
    }
  }, [getEmailSection]);
  
  React.useEffect(() => {
    if (
      (account.mssApiType === mssSubTypes.WEB || isReady(checkConfigSmtp))
      && isReady(checkConfigFolders)
    ) {
      
      dispatch(updateMssAccount(accessRights.psId, { ...account, validated: true }));
      onEnd();
    }
  }, [isReady(checkConfigFolders), isReady(checkConfigSmtp)]);
  
  
  const checkConfig = () => {
    if (
      account.mssLoginType !== mssLoginTypes.PSC
      || (account.mssLoginType === mssLoginTypes.PSC && userManager.loggedIn)
    ) {
      dispatch(updateMssAccount(accessRights.psId, { ...account, validated: false }));
      dispatch(clearSection(apiSections.MSS_GET_FOLDERS));
      dispatch(clearSection(apiSections.MSS_SEND_SMTP_EMAIL));
      dispatch(getAction(
        commands.getFoldersMSS,
        apiSections.MSS_GET_FOLDERS,
        account.mssEmail,
        {
          synchronous : true,
          subConfig   : account.mssApiType,
          contextExtra: { mssApiType: account.mssApiType, save: false, forceAction: errorActions.NONE },
          subSection  : 'check-config',
          silentError : true,
        },
      ));
      
      const emailContent = {
        sender          : account.mssEmail,
        title           : 'test d\'envoi d\'un message depuis Efficience',
        messageContent  : 'test d\'envoi d\'un message depuis Efficience',
        recipients      : account.mssEmail,
        isHtml          : false,
        replyTo         : account.mssReplyTo,
        senderWording   : account.mssSenderWording,
        messageId       : generateMessageId(),
        attachments     : [],
        otherAttachments: [],
      };
      dispatch(getSendMssEmailAction(
        account.mssApiType,
        apiType,
        emailContent,
        esUser,
        undefined,
        'check-config'
      ));
    } else {
      dispatch(updateMssAccount(accessRights.psId, { ...account, validated: true }));
      onEnd();
    }
  };
  
  
  React.useEffect(() => {
    if (waitForWsCert) {
      dispatch(getAction(
        commands.setMssEsWebServiceCertificate,
        apiSections.MSS_SET_WS_CERT,
        account.esMssCertificate !== mssAccount.esMssCertificate && !account.esMssWsCertificate
        ? account.esMssCertificate
        : account.esMssWsCertificate,
        {
          silentError: true,
        },
      ));
    }
  }, [waitForWsCert]);
  
  React.useEffect(() => {
    if (waitForImapCert) {
      dispatch(getAction(
        commands.setMssEsImapCertificate,
        apiSections.MSS_SET_IMAP_CERT,
        account.esMssCertificate !== mssAccount.esMssCertificate && !account.esMssImapCertificate
        ? account.esMssCertificate
        : account.esMssImapCertificate,
        {
          silentError: true,
        },
      ));
    }
  }, [waitForImapCert]);
  
  React.useEffect(() => {
    if (waitForSmtpCert) {
      dispatch(getAction(
        commands.setMssEsSmtpCertificate,
        apiSections.MSS_SET_SMTP_CERT,
        account.esMssCertificate !== mssAccount.esMssCertificate && !account.esMssSmtpCertificate
        ? account.esMssCertificate
        : account.esMssSmtpCertificate,
        {
          silentError: true,
        },
      ));
    }
  }, [waitForSmtpCert]);
  
  React.useEffect(() => {
    const wsOk   = !waitForWsCert || (waitForWsCert && isReady(setWsCertSection));
    const imapOk = !waitForImapCert || (waitForImapCert && isReady(setImapCertSection));
    const smtpOk = !waitForSmtpCert || (waitForSmtpCert && isReady(setSmtpCertSection));
    
    const wsKO   = waitForWsCert && hasError(setWsCertSection);
    const imapKO = waitForImapCert && hasError(setImapCertSection);
    const smtpKO = waitForSmtpCert && hasError(setSmtpCertSection);
    
    if (waitForCerts && wsOk && imapOk && smtpOk) {
      checkConfig();
      setSubmitting(false);
      setWaitForCerts(false);
      
      setWaitForImapCert(false);
      setWaitForSmtpCert(false);
      setWaitForWsCert(false);
      
      setChanged(false);
    }
    
    if (waitForCerts && (wsKO || imapKO || smtpKO)) {
      setSubmitting(false);
      setWaitForCerts(false);
      
      setWaitForImapCert(false);
      setWaitForSmtpCert(false);
      setWaitForWsCert(false);
    }
  }, [
    waitForWsCert, waitForImapCert, waitForSmtpCert,
    setWsCertSection, setImapCertSection, setSmtpCertSection,
    waitForCerts,
  ]);
  
  const submitMss = () => {
    setSubmitting(true);
    
    onSubmit();
    dispatch(clearSection(apiSections.MSS_GET_EMAIL));
    dispatch(clearSection(apiSections.MSS_SET_SMTP_CERT));
    dispatch(clearSection(apiSections.MSS_SET_IMAP_CERT));
    dispatch(clearSection(apiSections.MSS_SET_WS_CERT));
    dispatch(clearSubSection(apiSections.MSS_GET_FOLDERS, 'check-config'));
    dispatch(clearSubSection(apiSections.MSS_SEND_SMTP_EMAIL, 'check-config'));
    
    if (account.id) {
      dispatch(updateMssAccount(accessRights.psId, account));
    } else {
      let newAccount = { ...account, id: generateUniqueId() };
      dispatch(addMssAccount(accessRights.psId, newAccount));
      dispatch(setMssAccountActive(accessRights.psId, newAccount));
      setAccount(newAccount);
    }
    
    dispatch(setPersistedAppConfiguration('mssOtpChannelType', ''));
    dispatch(setMssConfiguration('mssPscAccessToken', pscAccessToken));
    
    let waitForSetCertsResults = false;
    
    if (
      [API_TYPES.WS, API_TYPES.JSPOST].includes(apiType)
      && (
        (!!account.esMssImapCertificate && account.esMssImapCertificate !== mssAccount.esMssImapCertificate)
        || (account.esMssCertificate !== mssAccount.esMssCertificate && !account.esMssImapCertificate)
      )
    ) {
      setWaitForImapCert(true);
      waitForSetCertsResults = true;
    }
    
    if (
      [API_TYPES.WS, API_TYPES.JSPOST].includes(apiType)
      && (
        (!!account.esMssSmtpCertificate && account.esMssSmtpCertificate !== mssAccount.esMssSmtpCertificate)
        || (account.esMssCertificate !== mssAccount.esMssCertificate && !account.esMssSmtpCertificate)
      )
    ) {
      setWaitForSmtpCert(true);
      waitForSetCertsResults = true;
    }
    
    if (
      [API_TYPES.WS, API_TYPES.JSPOST].includes(apiType)
      && (
        (!!account.esMssWsCertificate && account.esMssWsCertificate !== mssAccount.esMssWsCertificate)
        || (account.esMssCertificate !== mssAccount.esMssCertificate && !account.esMssWsCertificate)
      )
    ) {
      setWaitForWsCert(true);
      waitForSetCertsResults = true;
    }
    
    toast(
      'La configuration MSS à été mise à jour',
      {
        type           : 'success',
        position       : 'top-right',
        closeOnClick   : true,
        autoClose      : true,
        hideProgressBar: true,
      },
    );
    setWaitForCerts(waitForSetCertsResults);
    if (!waitForSetCertsResults) {
      checkConfig();
      setSubmitting(false);
      setChanged(false);
    }
  };
  
  const performMssAuthentication = () => {
    submitMss();
    dispatch(getAction(
      commands.performMssAuthentication,
      apiSections.MSS_PERFORM_AUTHENTICATION,
      formatPerformMssAuthentication(rpps),
    ));
  };
  
  const getEmailFromCPS = () => {
    dispatch(setUserConfiguration(accessRights.psId, { mssEmail: '' }));
    setAccount(prevAccount => ({
      ...prevAccount,
      mssEmail: '',
    }));
    setEmails([]);
    dispatch(getAction(
      commands.getEmailAdress,
      apiSections.MSS_GET_EMAIL,
      accessRights.psId,
      { silentError: true },
    ));
  };
  
  const onChangeAccountValue = (event) => {
    event.persist();
    
    setAccount(prevAccount => {
      let newAccount = {
        ...prevAccount,
        [event.target.name]: event.target.value,
      };
      
      return newAccount;
    });
  };
  
  return (
    <>
      <div>
        {config && (
          <Form.Group className="mb-0">
            <Form.Label>
              Opérateur
            </Form.Label>
            <Form.Control
              as="select"
              name="mssOperator"
              onChange={event => onChangeAccountValue(event)}
              value={account.mssOperator}
              disabled={!mssActive}
            >
              <option key="mss-config-null" defaultValue={!account.mssOperator} disabled>Sélectionner une valeur</option>
              {mssDefaultConfig.mssOperatorsConfig.map(operatorConfig => (
                <option key={`mss-config-${operatorConfig.id}`} value={operatorConfig.id}>{operatorConfig.name}</option>
              ))}
              <option key="mss-config-custom" value="custom">Personnalisé</option>
            </Form.Control>
            <div className="mt-3">
              {account.mssOperator === mssOperators.MAILIZ && (
                <Alert type="info" className="mb-0">
                  Mailiz est l&apos;opérateur de messagerie sécurisée de santé (MSS) gratuite de l&apos;ANS (Agence du Numérique en
                  Santé).
                  <br/>
                  Tout professionnel de santé peut créer son compte sur :
                  {' '}
                  <a
                    href={Number(env.REACT_APP_PRODUCTON_MODE) === 1
                          ? 'https://mailiz.mssante.fr/'
                          : 'https://mailiz.formation.mssante.fr/'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {Number(env.REACT_APP_PRODUCTON_MODE) === 1
                     ? 'https://mailiz.mssante.fr/'
                     : 'https://mailiz.formation.mssante.fr/'
                    }
                  </a>
                  .
                </Alert>
              )}
              {account.mssOperator === mssOperators.OTHERS && (
                <Alert type="info" className="mb-0">
                  Ce choix vous permet d&apos;accéder à tout opérateur de MSS conforme SEGUR.
                  <br/>
                  Tous les opérateurs ne sont pas encore conformes.
                  <br/>
                  Les réglages de l&apos;accès technique à la messagerie sont manuels.
                  <br/>
                  Dans le futur ces réglages seront effectués automatiquement.
                  <br/>
                  Merci de vous rapprocher de votre éditeur ou d&apos;icanopée pour configurer cette section.
                </Alert>
              )}
            </div>
          </Form.Group>
        )}
        
        
        {title && (
          <h5 className="mb-4">{title}</h5>
        )}
        
        {getEmailError && (
          <ErrorMessage
            error={getEmailError}
            message={(
              <>
                L&apos;utilisation de la messagerie MSS nécessite un compte Mailiz.
                <br/>
                Si vous n&apos;en disposez pas, vous pouvez en créer un depuis cette adresse :
                {' '}
                <a
                  href={Number(env.REACT_APP_PRODUCTON_MODE) === 1
                        ? 'https://mailiz.mssante.fr/'
                        : 'https://mailiz.formation.mssante.fr/'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Mailiz
                </a>
              </>
            )}
            showDetails
          />
        )}
        
        {config && (
          <>
            {account.mssApiType === mssSubTypes.IMAP && (showLoginPassword || account.mssOperator === 'custom') && (
              <>
                <div className="admin-content-divider"/>
                <h6 className="mt-3">IMAP/SMTP</h6>
                <Form.Group>
                  <Form.Label>
                    Imap login
                  </Form.Label>
                  <Form.Control
                    className="w-full"
                    onChange={event => onChangeAccountValue(event)}
                    name="mssImapLogin"
                    value={account.mssImapLogin || ''}
                    disabled={!mssActive}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Imap Password
                  </Form.Label>
                  <Form.Control
                    className="w-full"
                    onChange={event => onChangeAccountValue(event)}
                    name="mssImapPasswd"
                    value={account.mssImapPasswd || ''}
                    disabled={!mssActive}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Imap SaslLogin
                  </Form.Label>
                  <Form.Control
                    className="w-full"
                    onChange={event => onChangeAccountValue(event)}
                    name="mssImapSaslLogin"
                    value={account.mssImapSaslLogin || ''}
                    disabled={!mssActive}
                  />
                </Form.Group>
                
                <Form.Group>
                  <Form.Label>
                    Smtp login
                  </Form.Label>
                  <Form.Control
                    className="w-full"
                    onChange={event => onChangeAccountValue(event)}
                    name="mssSmtpLogin"
                    value={account.mssSmtpLogin || ''}
                    disabled={!mssActive}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Smtp Password
                  </Form.Label>
                  <Form.Control
                    className="w-full"
                    onChange={event => onChangeAccountValue(event)}
                    name="mssSmtpPasswd"
                    value={account.mssSmtpPasswd || ''}
                    disabled={!mssActive}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Smtp SaslLogin
                  </Form.Label>
                  <Form.Control
                    className="w-full"
                    onChange={event => onChangeAccountValue(event)}
                    name="mssSmtpSaslLogin"
                    value={account.mssSmtpSaslLogin || ''}
                    disabled={!mssActive}
                  />
                </Form.Group>
                
                {account.mssLoginType === mssLoginTypes.PSC && (
                  <>
                    <div className="admin-content-divider"/>
                    <h6 className="mt-3">Pro Santé Connect</h6>
                    <Form.Group>
                      <Form.Label>
                        JWT Access Token
                      </Form.Label>
                      <Form.Control
                        className="w-full"
                        onChange={event => setPscAccessToken(event.target.value)}
                        name="mssPscAccessToken"
                        value={pscAccessToken || ''}
                        disabled={!mssActive}
                      />
                    </Form.Group>
                  </>
                )}
              </>
            )}
            {account.mssLoginType === mssLoginTypes.CERT && apiType === API_TYPES.REST && (
              <>
                <div className="admin-content-divider"/>
                <h6 className="mt-3">Authentification par certificat logiciel</h6>
                <Form.Group>
                  <Form.Label>
                    ES_ID MSS
                  </Form.Label>
                  <Form.Control
                    className="w-full"
                    as="textarea"
                    onChange={event => onChangeAccountValue(event)}
                    name="mssEsId"
                    value={account.mssEsId || ''}
                    disabled={!mssActive}
                    rows={4}
                  />
                </Form.Group>
                <Form.Text className="small">
                  Identifiant du certificat présent dans le connecteur DmpConnect ES-REST
                </Form.Text>
              </>
            )}
            {account.mssLoginType === mssLoginTypes.CERT && apiType !== API_TYPES.REST && (
              <>
                <div className="admin-content-divider"/>
                <h6 className="mt-3">Authentification par certificat logiciel</h6>
                <Form.Group>
                  <Form.Label>
                    Certificat MSS global
                  </Form.Label>
                  <Form.Control
                    className="w-full"
                    as="textarea"
                    onChange={event => onChangeAccountValue(event)}
                    name="esMssCertificate"
                    value={account.esMssCertificate || ''}
                    disabled={!mssActive}
                    rows={4}
                  />
                  <Form.Text className="small">
                    Certificat utilisé pour les appels aux Web Services Mailiz ou pour les appels IMAP/SMTP.
                  </Form.Text>
                </Form.Group>
                {account.mssApiType === mssSubTypes.IMAP && (
                  <>
                    <Form.Group>
                      <Form.Label>
                        Certificat MSS IMAP
                      </Form.Label>
                      <Form.Control
                        className="w-full"
                        as="textarea"
                        onChange={event => onChangeAccountValue(event)}
                        name="esMssImapCertificate"
                        value={account.esMssImapCertificate || ''}
                        disabled={!mssActive}
                        rows={4}
                      />
                      <Form.Text className="small">
                        Remplace le certificat MSS global pour tous les appels IMAP.
                      </Form.Text>
                      {hasError(setImapCertSection) && (
                        <ErrorMessage
                          error={getError(setImapCertSection)}
                          message={(
                            <>
                              Une erreur s&apos;est produite lors de l&apos;enregistrement du certificat.
                              {getError(setImapCertSection).s_apiErrorExtendedInformations.indexOf('Unknown command:') !== -1 && (
                                <>
                                  <br/>
                                  Il faudra vous reconnecter pour que la modification soit prise en compte.
                                </>
                              )}
                            </>
                          )}
                          showDetails
                        />
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>
                        Certificat MSS SMTP
                      </Form.Label>
                      <Form.Control
                        className="w-full"
                        as="textarea"
                        onChange={event => onChangeAccountValue(event)}
                        name="esMssSmtpCertificate"
                        value={account.esMssSmtpCertificate || ''}
                        // disabled={!mssActive}
                        rows={4}
                      />
                      <Form.Text className="small">
                        Remplace le certificat MSS global pour tous les appels SMTP.
                      </Form.Text>
                      {hasError(setSmtpCertSection) && (
                        <ErrorMessage
                          error={getError(setSmtpCertSection)}
                          message={(
                            <>
                              Une erreur s&apos;est produite lors de l&apos;enregistrement du certificat.
                              {getError(setSmtpCertSection).s_apiErrorExtendedInformations.indexOf('Unknown command:') !== -1 && (
                                <>
                                  <br/>
                                  Il faudra vous reconnecter pour que la modification soit prise en compte.
                                </>
                              )}
                            </>
                          )}
                          showDetails
                        />
                      )}
                    </Form.Group>
                  </>
                )}
                {account.mssApiType === mssSubTypes.WEB && (
                  <Form.Group>
                    <Form.Label>
                      Certificat MSS WebService
                    </Form.Label>
                    <Form.Control
                      className="w-full"
                      as="textarea"
                      onChange={event => onChangeAccountValue(event)}
                      name="esMssWsCertificate"
                      value={account.esMssWsCertificate || ''}
                      disabled={!mssActive}
                      rows={4}
                    />
                    <Form.Text className="small">
                      Remplace le certificat MSS global pour tous les appels aux WebService Mailiz.
                    </Form.Text>
                    {hasError(setWsCertSection) && (
                      <ErrorMessage
                        error={getError(setWsCertSection)}
                        message={(
                          <>
                            Une erreur s&apos;est produite lors de l&apos;enregistrement du certificat.
                            {getError(setWsCertSection).s_apiErrorExtendedInformations.indexOf('Unknown command:') !== -1 && (
                              <>
                                <br/>
                                Il faudra vous reconnecter pour que la modification soit prise en compte.
                              </>
                            )}
                          </>
                        )}
                        showDetails
                      />
                    )}
                  </Form.Group>
                )}
              </>
            )}
          </>
        )}
        <div className="admin-content-divider"/>
        <h6 className="mt-3">Configuration du compte</h6>
        
        <Form.Group as={columnLayout ? Row : undefined}>
          <Form.Label column={columnLayout} sm="4">
            Libellé du compte
          </Form.Label>
          <div className={columnLayout ? 'col-sm-8' : undefined}>
            <Form.Control
              className="w-full"
              id="label"
              disabled={!mssActive}
              onChange={event => onChangeAccountValue(event)}
              name="label"
              value={account.label || ''}
              placeholder={getDefaultMssAccountLabel(account, mssAccounts, mssDefaultConfig.mssOperatorsConfig)}
            />
            {!account.mssEmail && <Form.Control.Feedback type="invalid">Ce champs est requis</Form.Control.Feedback>}
            {!!account.mssEmail && !isEmailValid(account.mssEmail) && (
              <Form.Control.Feedback type="invalid">Adresse email invalide</Form.Control.Feedback>
            )}
          </div>
        </Form.Group>
        
        {account.mssLoginType === mssLoginTypes.CPX && emails.length > 1 ? (
          <Form.Group as={columnLayout ? Row : undefined}>
            <Form.Label column={columnLayout} sm="4">
              Adresse Email
            </Form.Label>
            <div className={columnLayout ? 'col-sm-8' : undefined}>
              <Form.Control
                className="w-full"
                as="select"
                id="mssEmail"
                disabled={!mssActive}
                onChange={event => onChangeAccountValue(event)}
                name="mssEmail"
                value={account.mssEmail || ''}
                isInvalid={!isEmailValid(account.mssEmail)}
              >
                {emails.map(e => <option key={e} value={e}>{e}</option>)}
              </Form.Control>
              {!account.mssEmail && <Form.Control.Feedback type="invalid">Ce champs est requis</Form.Control.Feedback>}
              {!!account.mssEmail && !isEmailValid(account.mssEmail) && (
                <Form.Control.Feedback type="invalid">Adresse email invalide</Form.Control.Feedback>
              )}
            </div>
            {/* <Form.Control.Feedback type="invalid"> */}
            {/* {errors.mssEmail} */}
            {/* </Form.Control.Feedback> */}
          </Form.Group>
        ) : (
           <Form.Group as={columnLayout ? Row : undefined}>
             <Form.Label column={columnLayout} sm="4">
               Adresse Email
             </Form.Label>
             <div className={columnLayout ? 'col-sm-8' : undefined}>
               <Form.Control
                 className="w-full"
                 id="mssEmail"
                 disabled={!mssActive}
                 onChange={event => onChangeAccountValue(event)}
                 name="mssEmail"
                 value={account.mssEmail || ''}
                 isInvalid={!isEmailValid(account.mssEmail)}
               />
               {!account.mssEmail && <Form.Control.Feedback type="invalid">Ce champs est requis</Form.Control.Feedback>}
               {!!account.mssEmail && !isEmailValid(account.mssEmail) && (
                 <Form.Control.Feedback type="invalid">Adresse email invalide</Form.Control.Feedback>
               )}
             </div>
           </Form.Group>
         )}
        {account.mssApiType === mssSubTypes.WEB && account.mssLoginType === mssLoginTypes.CERT && (
          <Form.Group controlId="rpps">
            <Form.Label>
              Identifiant national (RPPS)
            </Form.Label>
            <Form.Control
              value={rpps || ''}
              onChange={event => setRpps(event.target.value)}
              disabled={!mssActive}
            />
          </Form.Group>
        )}
        {!config && (
          <>
            {account.mssLoginType === mssLoginTypes.CERT && account.mssApiType === mssSubTypes.WEB ? (
              <Form.Group>
                <ButtonWithLoader
                  disabled={!rpps}
                  label="Se connecter"
                  loading={isLoading(performMssAuthSection)}
                  onClick={() => performMssAuthentication()}
                />
              </Form.Group>
            ) : (
               <Form.Group>
                 <ButtonWithLoader
                   disabled={!mssActive || !MssConfigSchema.isValidSync({
                     mssEmail: account.mssEmail,
                   })}
                   label="Se connecter"
                   loading={submitting}
                   onClick={() => submitMss()}
                 />
               </Form.Group>
             )}
          </>
        )}
        
        {accessRights.authenticationType === authenticationTypes.DIRECT && (
          <>
            {!config && <div className="typography-big-text text-gray-300 mb-3">ou</div>}
            
            <Form.Group>
              <ButtonWithLoader
                disabled={!mssActive}
                loading={getEmailIsLoading}
                label="Obtenir l'email depuis la carte CPS"
                loadingLabel=""
                variant="outline-secondary"
                onClick={() => getEmailFromCPS()}
                className="mr-2"
              />
            </Form.Group>
          </>
        )}
        
        {config && (
          <>
            <Form.Group as={columnLayout ? Row : undefined}>
              
              <Form.Label column={columnLayout} sm="4">
                Libellé de l&apos;expéditeur
                <TitleTooltip
                  id="sender-wording-tooltip"
                  text={(
                    <div className="text-left">
                      <h6 className="text-white">Exemples :</h6>
                      <ul>
                        <li>Boite mail personnelle professionnelle : Dr Marie MARTIN + optionnellement le nom du service / de
                          l&apos;entité
                          fonctionnelle
                        </li>
                        <li>Boite mail organisationnelle : Hôpital A – Service Cardiologie</li>
                      </ul>
                    </div>
                  )}
                >
                  <i className="ic-info ml-2"/>
                </TitleTooltip>
              </Form.Label>
              <div className={columnLayout ? 'col-sm-8' : undefined}>
                <Form.Control
                  className="w-full"
                  id="mssSenderWording"
                  disabled={!mssActive}
                  onChange={event => onChangeAccountValue(event)}
                  name="mssSenderWording"
                  value={account.mssSenderWording || ''}
                />
              </div>
            </Form.Group>
            
            {config && (
              <>
                <div className="btn-group-toggle mb-3">
                  <Button
                    size="sm"
                    className={`toggle-button ${advanced ? 'active' : ''}`}
                    onClick={() => setAdvanced(!advanced)}
                  >
              <span>
                {advanced ? 'Cacher ' : 'Afficher '}
                la configuration avancée
              </span>
                  </Button>
                </div>
                {advanced && (
                  <div id="advanced-mss-config">
                    <h6 className="mt-3">Configuration avancée</h6>
                    
                    <Form.Group>
                      <Form.Label>
                        Type d&apos;accus&eacute; de r&eacute;ception
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="mssReceiptNotificationType"
                        onChange={event => setReceiptNotificationType(event.target.value)}
                        value={receiptNotificationType}
                        disabled={!mssActive}
                      >
                        <option key={mssReceiptNotificationTypes.DSN} value={mssReceiptNotificationTypes.DSN}>
                          Delivery Status Notification (DSN) (expérimental)
                        </option>
                        <option key={mssReceiptNotificationTypes.MDN} value={mssReceiptNotificationTypes.MDN}>
                          Message Delivery Notification (MDN)
                        </option>
                      </Form.Control>
                    </Form.Group>
                    
                    <Form.Group as={columnLayout ? Row : undefined}>
                      <Form.Label column={columnLayout} sm="4">
                        Adresse de réponse
                      </Form.Label>
                      <div className={columnLayout ? 'col-sm-8' : undefined}>
                        <Form.Control
                          id="mssReplyTo"
                          disabled={config && !mssActive}
                          onChange={event => onChangeAccountValue(event)}
                          name="mssReplyTo"
                          value={account.mssReplyTo}
                        />
                      </div>
                    </Form.Group>
                  </div>
                )}
              </>
            )}
            
            {(isLoading(checkConfigFolders) || isReady(checkConfigFolders) || hasError(checkConfigFolders)) && (
              <div className="mb-4">
                <h6 className="mt-3">Test de la configuration</h6>
                <div>
                  {isLoading(checkConfigFolders) && (
                    <Loading size="sm" centered={false} message="Test d'accès à la boite de messagerie"/>
                  )}
                  {isReady(checkConfigFolders)
                   && (
                     account.mssApiType === mssSubTypes.WEB
                     || (checkConfigFolders.SubFolder && checkConfigFolders.SubFolder.length > 0)
                   )
                   && (
                     <div className="d-flex flex-row align-items-center justify-content-start">
                       <i className="alert-icon alert-icon-success mr-2"/>
                       <span>Test d&apos;accès à la boite de messagerie - OK</span>
                     </div>
                   )}
                  {(
                     hasError(checkConfigFolders)
                     || (
                       isReady(checkConfigFolders)
                       && checkConfigFolders.SubFolder
                       && checkConfigFolders.SubFolder.length === 0
                     )) && (
                     <div className="d-flex flex-row align-items-center justify-content-start">
                       <i className="alert-icon alert-icon-danger mr-2"/>
                       <span>Test d&apos;accès à la boite de messagerie - Erreur</span>
                       <Button
                         className="ml-3 mb-2"
                         variant="outline-danger"
                         size="sm"
                         onClick={() => toggleCheckConfigImapErrorDetails(true)}
                       >
                         Afficher le détail de l&apos;erreur
                       </Button>
                       {checkConfigImapErrorDetails && (
                         <Modal show onHide={() => toggleCheckConfigImapErrorDetails(false)}>
                           <Modal.Header closeButton>Erreur détaillée</Modal.Header>
                           <Modal.Body>
                             {hasError(checkConfigFolders) ? (
                               <ErrorDetails
                                 details={[createErrorDetails(
                                   'erreur',
                                   objectWithoutTheseKeys(
                                     { detailsType: 'error', ...getError(checkConfigFolders) },
                                     ['customErrorMessage'],
                                   ),
                                 )]}
                                 error={getError(checkConfigFolders)}
                               />
                             ) : (
                                <>
                                  Impossible de récupérer la liste des dossiers de la boite de messagerie.
                                  {' '}
                                  <br/>
                                  Cela peut indiquer un problème avec le compte de messagerie ou avec l&apos;accès au serveur.
                                </>
                              )}
                           </Modal.Body>
                           <Modal.Footer className="d-flex justify-content-between">
                             <GetLogs
                               onOpen={() => toggleCheckConfigImapErrorDetails(false)}
                               error={objectWithoutTheseKeys(
                                 { detailsType: 'error', ...getError(checkConfigFolders) },
                                 ['customErrorMessage'],
                               )}
                             />
                             <Button
                               variant="outline-secondary"
                               onClick={() => toggleCheckConfigImapErrorDetails(false)}
                             >
                               Fermer
                             </Button>
                           </Modal.Footer>
                         </Modal>
                       )}
                     </div>
                   )}
                </div>
                <div>
                  {isLoading(checkConfigSmtp) && (
                    <Loading size="sm" centered={false} message="Test d'envoi d'un message"/>
                  )}
                  {isReady(checkConfigSmtp) && (
                    <div className="d-flex flex-row align-items-center justify-content-start">
                      <i className="alert-icon alert-icon-success mr-2"/>
                      <span>Test d&apos;envoi d&apos;un message - OK</span>
                    </div>
                  )}
                  {hasError(checkConfigSmtp) && (
                    <div className="d-flex flex-row align-items-center justify-content-start">
                      <i className="alert-icon alert-icon-danger mr-2"/>
                      <span>Test d&apos;envoi d&apos;un message - Erreur</span>
                      <Button
                        className="ml-3"
                        variant="outline-danger"
                        size="sm"
                        onClick={() => toggleCheckConfigSmtpErrorDetails(true)}
                      >
                        Afficher le détail de l&apos;erreur
                      </Button>
                      {checkConfigSmtpErrorDetails && (
                        <Modal show onHide={() => toggleCheckConfigSmtpErrorDetails(false)}>
                          <Modal.Header closeButton>Erreur détaillée</Modal.Header>
                          <Modal.Body>
                            <ErrorDetails
                              details={[createErrorDetails(
                                'erreur',
                                objectWithoutTheseKeys(
                                  { detailsType: 'error', ...getError(checkConfigSmtp) },
                                  ['customErrorMessage'],
                                ),
                              )]}
                              error={getError(checkConfigSmtp)}
                            />
                          </Modal.Body>
                          <Modal.Footer className="d-flex justify-content-between">
                            <GetLogs
                              onOpen={() => toggleCheckConfigSmtpErrorDetails(false)}
                              error={objectWithoutTheseKeys(
                                { detailsType: 'error', ...getError(checkConfigFolders) },
                                ['customErrorMessage'],
                              )}
                            />
                            <Button
                              variant="outline-secondary"
                              onClick={() => toggleCheckConfigSmtpErrorDetails(false)}
                            >
                              Fermer
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            
            <Form.Group>
              <ButtonWithLoader
                disabled={(mssAccount.validated && !changed) || submitting || !isEmailValid(account.mssEmail)}
                label={account.mssLoginType === mssLoginTypes.PSC && !userManager.loggedIn
                       ? 'Enregistrer la configuration'
                       : 'Valider la configuration'}
                loading={submitting || isLoading(checkConfigFolders) || isLoading(checkConfigSmtp)}
                onClick={() => submitMss()}
              />
            </Form.Group>
          </>
        )}
      </div>
    </>
  );
}

MssConfig.propTypes    = {
  dispatch             : PropTypes.func.isRequired,
  apiType              : PropTypes.string.isRequired,
  getEmailIsLoading    : PropTypes.bool,
  getEmailError        : PropTypes.object,
  performMssAuthSection: PropTypes.object,
  esUser               : PropTypes.object,
  accessRights         : PropTypes.object.isRequired,
  columnLayout         : PropTypes.bool,
  mssActive            : PropTypes.bool,
  config               : PropTypes.bool,
  title                : PropTypes.string,
  mssPscAccessToken    : PropTypes.string,
  onSubmit             : PropTypes.func.isRequired,
  onEnd                : PropTypes.func.isRequired,
  getEmailSection      : PropTypes.object,
  checkConfigFolders   : PropTypes.object,
  checkConfigSmtp      : PropTypes.object,
  setImapCertSection   : PropTypes.object,
  setSmtpCertSection   : PropTypes.object,
  setWsCertSection     : PropTypes.object,
  mssDefaultConfig     : PropTypes.object,
  mssAccounts          : PropTypes.array,
  mssAccount           : PropTypes.object,
};
MssConfig.defaultProps = {
  esUser               : undefined,
  getEmailIsLoading    : false,
  getEmailError        : {},
  performMssAuthSection: {},
  columnLayout         : true,
  mssActive            : false,
  config               : true,
  title                : '',
  getEmailSection      : {},
  checkConfigFolders   : undefined,
  checkConfigSmtp      : undefined,
  setImapCertSection   : {},
  setSmtpCertSection   : {},
  setWsCertSection     : {},
  mssDefaultConfig     : undefined,
  mssAccounts          : [],
  mssAccount           : {}
};

function mapStateToProps(state) {
  const
    {
      dmpconnect: {
        [apiSections.MSS_GET_EMAIL]             : getEmailSection,
        [apiSections.MSS_PERFORM_AUTHENTICATION]: performMssAuth,
        [apiSections.MSS_GET_FOLDERS]           : {
          'check-config': checkConfigFolders,
        },
        [apiSections.MSS_SEND_SMTP_EMAIL]       : {
          'check-config': checkConfigSmtp,
        },
        [apiSections.MSS_SET_IMAP_CERT]         : setImapCertSection,
        [apiSections.MSS_SET_SMTP_CERT]         : setSmtpCertSection,
        [apiSections.MSS_SET_WS_CERT]           : setWsCertSection,
      },
      
      dmpconnectUser: { esUser },
      
      dmpconnectMSSConfiguration: {
        mssPscAccessToken,
      },
    } = state;
  
  return {
    apiType              : getApiType(state),
    getEmailIsLoading    : isLoading(getEmailSection),
    getEmailError        : getError(getEmailSection),
    getEmailSection,
    mssPscAccessToken,
    esUser,
    performMssAuthSection: performMssAuth,
    mssSenderWording     : getMssSenderWording(state),
    checkConfigFolders,
    checkConfigSmtp,
    setImapCertSection,
    setSmtpCertSection,
    setWsCertSection,
  };
}

export default checkAccessRights(connect(mapStateToProps)(MssConfig));
