import env                      from '../../envVariables';
import { ohifActionsConstants } from '../constants';

const initialState = {
  documentKOSTitleTrigger: '',
  
  ohifKosViewerRootUrl        : env.REACT_APP_OHIF_VIEWER_ROOT_URL,
  ohifKosStudyPath            : env.REACT_APP_OHIF_VIEWER_STUDY_PATH,
  ohifKosSeriesPath           : env.REACT_APP_OHIF_VIEWER_SERIES_PATH,
  ohifKosPSCTokenBearer       : Number(env.REACT_APP_OHIF_VIEWER_PSC_TOKEN_BEARER) === 1,
  ohifKosStructIdNatHeaderName: env.REACT_APP_OHIF_VIEWER_STRUCT_ID_NAT_HEADER_NAME,
  ohifKosInBody               : Number(env.REACT_APP_OHIF_VIEWER_KOS_IN_BODY) === 1,
  ohifKosRequestParams        : env.REACT_APP_OHIF_VIEWER_REQUEST_PARAMS,
  
  ohifKosViewerDebugUrl: `https://ohifviewer.icanopee.net/viewer?StudyInstanceUIDs=1.3.6.1.4.1.14519.5.2.1.1706.8374.643249677828306008300337414785&initialSeriesInstanceUID=1.3.6.1.4.1.14519.5.2.1.1706.8374.134469846969371865041508269759`,
};

export function ohifConfig (state = initialState, action) {
  switch (action.type) {
    case ohifActionsConstants.RESET_OHIF_CONFIG:
      return initialState;
    case ohifActionsConstants.SET_OHIF_CONFIG:
      return { ...state, [action.key]: action.value };
    default:
      return state;
  }
}
