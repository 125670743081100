import { b64DecodeUnicode } from '../utils/dataUtils';

export const ohifKosViewerUrlDebugModes = {
  full   : 'full',
  partial: 'partial',
  none   : 'none',
};

export const generateViewerUrl = (ohifConfig, studyInstanceUid, serieInstanceUid) => {
  const
    {
      ohifKosViewerRootUrl,
      ohifKosStudyPath,
      ohifKosSeriesPath,
      ohifKosViewerDebugUrl,
    } = ohifConfig;
  
  if (ohifKosViewerRootUrl) {
    let url = ohifKosViewerRootUrl;
    if (studyInstanceUid && ohifKosStudyPath) {
      url = `${url}${ohifKosStudyPath}${studyInstanceUid}`;
    }
    if (serieInstanceUid && ohifKosSeriesPath) {
      url = `${url}/${ohifKosSeriesPath}${serieInstanceUid}`;
    }
    return url;
  }
  
  if (ohifKosViewerDebugUrl) {
    return ohifKosViewerDebugUrl;
  }
  
  // if (ohifKosViewerUrlDebugMode === ohifKosViewerUrlDebugModes.partial) {
  //   const url = `${ohifKosViewerHost}/viewer?StudyInstanceUIDs=${studyInstanceUid}`;
  //   if (serieInstanceUid) {
  //     return `${url}&initialSeriesInstanceUID=${serieInstanceUid}`;
  //   }
  //   return url;
  // }
  
  return undefined;
};

export const getSeriesDescFromSummary = (description) => {
  const lines    = description.split('\r\n');
  let seriesDesc = {};
  lines.forEach(line => {
    if (line.startsWith('Série-')) {
      const regex = /Série-(?<instanceUid>[\d.]+)\s:\s(?<modality>.*)\s@\s(?<laterality>.*)\s:\s(?<desc>.*)/;
      const
        {
          groups: {
            instanceUid,
            modality,
            laterality,
            desc,
          },
        }         = line.match(regex) || {};
      
      seriesDesc[instanceUid] = { modality, laterality, desc };
    }
  });
  
  return seriesDesc;
};

const getValue = (dicomNode) => b64DecodeUnicode(dicomNode.s_dataInBase64);

export const getKosSummary = (dicomContent = {}) => {
  const { RawStructure = [] } = dicomContent;
  const contentSequence       = RawStructure.find(i => i.s_tag === '(0040,a730)');
  if (contentSequence && contentSequence.Items && contentSequence.Items.length > 0) {
    return contentSequence.Items.reduce((text, item) => {
      if (item.Items && item.Items.length > 0) {
        const conceptNameCodeSequence = item.Items.find(j => j.s_tag === '(0040,a043)');
        
        if (conceptNameCodeSequence) {
          const relationShipType = item.Items.find(j => j.s_tag === '(0040,a010)');
          const valueType        = item.Items.find(j => j.s_tag === '(0040,a040)');
          
          if (getValue(relationShipType) === 'CONTAINS' && getValue(valueType) === 'TEXT') {
            const textValue = item.Items.find(j => j.s_tag === '(0040,a160)');
            
            if (textValue) {
              return getValue(textValue);
            }
          }
        }
      }
      
      return text;
    }, '');
  }
  
  return '';
};
